import React from "react";
import { graphql } from "gatsby";

import SearchPage from "../components/Search";
import Layout from "../components/layout";
import Breadcrumb from "../components/Breadcrumb";

export default function Search({ data, location }) {
  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/search/",
      }}
      path={"/search/"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <Breadcrumb
        type="page"
        current={{
          title: "Search results",
          slug: "search-results",
          uri: "/search",
        }}
      />
      <SearchPage brands={data.allWpBrand} state={location.state} />
    </Layout>
  );
}

export const query = graphql`
  query {
    allWpBrand {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
